import Vue from 'vue'
import SpeedAuditWidget from '../vue/website/audits/speed'

const initAuditWidget = function(data) {
  const props = {
    auditId: data.auditId
  }

  /* eslint-disable no-new */
  new Vue({
    el: document.getElementById('speedaudit-component'),
    render: function(h) {
      return h(SpeedAuditWidget, { props: props })
    }
  })
}

Styxie.Initializers.SpeedAudits = {
  show: (data) => {
    $(document).ready(() => {
      initAuditWidget(data)
    })
  }
}
