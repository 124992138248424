// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel'


import 'javascripts/bootstrap.min'
import 'javascripts/jquery.inview.min'
import 'javascripts/card.min'
import 'javascripts/gmap3.min'
import 'javascripts/imagesloaded.pkgd.min'
import 'javascripts/iziToast.min'
import 'javascripts/jquery.animateNumber.min'
import 'javascripts/jquery.countdown.min'
import 'javascripts/jquery.easing.min'
import 'javascripts/jquery.fancybox.min'
import 'javascripts/jquery.easy-autocomplete.min'
import 'javascripts/nouislider.min'
import 'javascripts/parallax-scroll.min'

// import 'javascripts/owl.carousel.data.min'

import 'stylesheets/feather.min.css'
import 'stylesheets/iziToast.min.css'
import 'stylesheets/jquery.fancybox.min.css'
import 'stylesheets/nouislider.min.css'
import 'stylesheets/socicon.min.css'
// import 'stylesheets/vendor.min.css'

import 'javascripts/theme.js'
