<template>
  <div>
    <div v-if="pendingAudit">
      <b-badge variant="secondary">Pending Audit</b-badge>
      <a href="#" @click.prevent="runAudit()">Run</a>
    </div>
    <div v-else>
      <b-badge variant="success">{{ this.value }}</b-badge>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import {
  BadgePlugin
} from 'bootstrap-vue'

Vue.use(BadgePlugin)

const PENDING_AUDIT_STATE = 'pending'

export default {
  props: ['value', 'params'],
  data() {
    return {
    }
  },
  computed: {
    formattedTitle() {
      return this.value
    }, 
    pendingAudit() {
      return this.value === PENDING_AUDIT_STATE
    }
  },
  methods: {
    runAudit() {
      const url = `/api/website_audits/${this.params.audit_id}/audit_pages/${this.params.page_id}/web_page_test_audit`

      axios.post(url)
        .then((response) => {
          /* eslint-disable no-alert */
          alert(`Audit started.`)
          /* eslint-enable no-alert */
        })
        .catch(() => {
          // console.log(res)
        })
      }
  }
}
</script>
