<template>
  <div>
    <table class="table">
      <thead>
      <tr>
        <th>URL</th>
        <th class="width10">
          <span class="cursor-pointer" @click="sortBy('issues')" :key="'sort-issues-' + sortData.col + sortData.order">
            Issues
            <i class="fas" :class="columnIconClass('issues')"></i>
          </span>
        </th>
        <th class="width10">
          <span class="cursor-pointer" @click="sortBy('words')" :key="'sort-words-' + sortData.col + sortData.order">
            Words
            <i class="fas" :class="columnIconClass('words')"></i>
          </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="page in pages" :key="page.url">
        <td><a :href="page.url" target="_blank">{{ page.url }}</a></td>
        <td>{{ page.issues_count }}</td>
        <td>{{ page.words_count }}</td>
      </tr>
      </tbody>
    </table>
    <nav class="pagination">
      <paginate
          :page-count="totalPagesCount"
          :container-class="'pages'"
          :click-handler="paginateHandler">
      </paginate>
    </nav>
  </div>
</template>

<script>
import axios from 'axios'
import Paginate from 'vuejs-paginate'

export default {
  props: {
    websiteId: { required: true }
  },
  components: {
    Paginate
  },
  data() {
    return {
      pages: [],
      totalPagesCount: 0,
      currentPage: 1,
      sortData: {
        col: null,
        order: null,
        count: 0
      }
    }
  },
  methods: {
    paginateHandler(page) {
      this.currentPage = page
      this.fetchPages(page)
    },
    fetchPages: function() {
      const url = `/api/websites/${this.websiteId}/pages`
      axios.get(url, {
        params: {
          page: this.currentPage,
          sort_by: this.sortData.col,
          order: this.sortData.order
        }
      }).then((res) => {
        this.totalPagesCount = res.data.pages_count
        this.pages = res.data.pages
      })
    },
    columnIconClass(col) {
      const orderClass = {
        asc: 'fa-sort-numeric-up',
        desc: 'fa-sort-numeric-down'
      }
      if (this.sortData.col !== col) {
        return 'fas fa-arrows-alt-v'
      }
      return orderClass[this.sortData.order]
    },
    sortBy(col) {
      const sortFlips = {
        asc: 'desc',
        desc: 'asc'
      }
      if (this.sortData.col === col) {
        this.sortData.count++
        if (this.sortData.count > 2) {
          this.sortData.col = null
          this.sortData.order = null
          this.sortData.count = 0
        } else {
          this.sortData.order = sortFlips[this.sortData.order]
        }
      } else {
        this.sortData.col = col
        this.sortData.count = 1
        this.sortData.order = 'asc'
      }
      this.fetchPages()
    }
  },
  created: function created() {
    this.fetchPages()
  }
}

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.width10 {
  width: 10%;
}
</style>
