import '../stylesheets/application.scss'

import 'init'
import 'theme'
import 'javascripts/styxie'
import '@fortawesome/fontawesome-free/js/all'
import toastr from 'toastr'

function importAll(r) {
  r.keys().forEach(r)
}

importAll(require.context('../styxies', true, /\.js$/))

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('../channels')
require('trix')
require('@rails/actiontext')
require.context('../images', true)

$(document).on('submit', 'form.audit-form', (e) => {
  e.preventDefault()
  const id = $('#website').val()
  const url = `/websites/${id}/website_audits`

  $.ajax({
    url: url,
    method: 'POST'
  }).done((data) => {
    window.location = data.url
  })
})

$(document).on('ajax:complete', '.run_keywords_report_configuration_btn', (e) => {
  if (e.detail[1] === 'OK') {
    toastr.success('Job passed in queue')
  }
})

$(document).on('ajax:beforeSend', '.run_keywords_report_configuration_btn', (e) => {
  $(e.target).prop({ disabled: 'disabled' })
  $(e.target).addClass('disabled')
})
