import Vue from 'vue'
import Website from '../vue/website/index.vue'

Styxie.Initializers.Websites = {
  show: (data) => {
    $(document).ready(() => {
      if (!data.synced) {
        return
      }
      /* eslint-disable no-new */
      new Vue({
        el: '#website-widget',
        render: (h) => h(Website, { props: { websiteId: data.id } })
      })
      /* eslint-enable no-new */
    })
  }
}
