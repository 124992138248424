<template>
  <div class="custom-control custom-checkbox">
    <input 
      type='checkbox'
      :checked="value"
      @change="toggleAuditIncluded" />
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'


export default {
  props: ['value', 'params'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    toggleAuditIncluded() {
      let url = `/api/website_audits/${this.params.audit_id}/audit_pages/${this.params.page_id}/audit_toggle`
      
      axios.post(url)
        .then((response) => {
          /* eslint-disable no-alert */
          console.log('added to audit.')
          /* eslint-enable no-alert */
        })
        .catch(() => {
          // console.log(res)
        })
    }
  }
}
</script>
