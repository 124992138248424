<template>
  <div class="row">
    <div class="col-3">
      <ul class="list-group">
        <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-for="rule in rules" :class="{active: rule.id === activeIssueRuleId}">
          <a href="#" @click.prevent="setActiveIssueRule(rule.id)">{{ rule.name }}</a>

          <span class="badge badge-primary badge-pill">{{ rule.crawl_audits_count }}</span>
        </li>
      </ul>
    </div>
    <div class="col-9">
      <div class="d-flex flex-wrap justify-content-between">
        <p class='mt-0 pt-0'>The crawl audit uses ScreamingFrog to programmatically detect technical issues with the
          site's meta title, descriptions, headers, URLs, content and more. </p>
      </div>
      <SlimTable v-if="tableUrl"
        :remoteUrl="tableUrl"
        :columns="columns"
        tableClass="table"
        :customFilters="customFilters"
        :perPage="10"
        totalRowsCountKey="total_count"
      >
        <template v-slot:td-url="sprops">
          <a :href="sprops.row.url" target="_blank">{{ sprops.row.url }}</a>
        </template>

        <template v-slot:td-publishable="sprops">
          <input type="checkbox" :checked="sprops.row.publishable" :value="sprops.row.id" @click="setPublishable" />
        </template>
      </SlimTable>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SlimTable from '../../../lib/slim_table.vue'

export default {
  props: {
    auditId: Number
  },
  data() {
    return {
      tableUrl: null,
      rules: [],
      activeIssueRuleId: null,
      activeIssueRuleName: null,
      // crawlAudits: []
      filter: '',
      filterVal: '',
      timeout: null,
      columns: [
        { title: 'URL', key: 'url' },
        { title: 'Result data', key: 'title', orderable: true },
        { title: 'Show in public', key: 'publishable', orderable: true }
      ]
    }
  },
  computed: {
    customFilters() {
      return {
        rule_id: this.activeIssueRuleId
      }
    }
  },
  created() {
    axios.get(`/api/audits/${this.auditId}/crawl/rules`).then((res) => {
      this.rules = res.data.rules
      const rule = res.data.rules[0]
      this.activeIssueRuleId = rule.id
      this.activeIssueRuleName = rule.name
      this.tableUrl = `/api/audits/${this.auditId}/crawl`
    })
  },
  methods: {
    setActiveIssueRule: function(id) {
      this.activeIssueRuleId = id
      this.activeIssueRuleName = this.rules.find((r) => r.id === id).name
    },
    setPublishable(e) {
      const val = e.target.checked
      const id = e.target.value
      axios.patch(`/api/audits/${this.auditId}/crawl/${id}`, {
        publishable: val
      })
    }
  },
  components: { SlimTable }
}
</script>
