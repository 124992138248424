<template>
  <div>
    <VueSlimTables
      :remoteUrl="remoteUrl"
      :columns="columns"
      tableClass="table_full_width table table-hover"
      :perPage="10"
      ref="table"
      :auditId="auditId"
    />
  </div>
</template>

<script>

import Vue from 'vue'
import VueSlimTables from '../../../../lib/slim_table.vue'
import WptStatusRow from './wpt_status_row'
import PsStatusRow from './ps_status_row'
import WptMetricsRow from './wpt_metrics_row'
import PsMetricsRow from './ps_metrics_row'
import AuditIncludedRow from './audit_included_row'
import ActionCable from 'actioncable'

export const App = App || {}

Vue.use(VueSlimTables)

export default {
  name: 'PagesTable',
  props: {
    auditId: Number
  },
  data() {
    return {
      columns: [
        { title: 'URL', key: 'url' },
        { title: 'WPT Status', key: 'wpt_status', component: WptStatusRow },
        { title: 'Ps Status', key: 'ps_status', component: PsStatusRow },
        { title: 'Ps Metrics', key: 'ps_metrics', component: PsMetricsRow },
        { title: 'Wpt Metrics', key: 'wpt_metrics', component: WptMetricsRow },
        { title: 'Included', key: 'audit_included', component: AuditIncludedRow }
      ]
    }
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    }
  },
  computed: {
    remoteUrl: function() {
      return `/api/website_audits/${this.auditId}/audit_pages`
    }
  },
  mounted() {
    this.cable = ActionCable.createConsumer()
    const vue_this = this

    const wpt_audit_sub = this.cable.subscriptions.create('WptAuditChannel', {
      received: (message) => {
        vue_this.reload()
      },
      connected: () => {
        console.log('connected to WptAuditChannel')
      }
    })

    const ps_audit_sub = this.cable.subscriptions.create('PsAuditChannel', {
      received: (message) => {
        vue_this.reload()
      },
      connected: () => {
        console.log('connected to PsAuditChannel')
      }
    })

  },
  components: { VueSlimTables, WptStatusRow, PsStatusRow, WptMetricsRow, PsMetricsRow }
}

</script>
