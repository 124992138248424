<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(item, index) in menuItems">
        <a href="#" class="nav-link" :class="{active: item.active}" @click="toggleMenu(index)">{{ item.title }}</a>
      </li>
    </ul>
    <keep-alive>
      <component :is="currentComponent" :websiteId="websiteId"></component>
    </keep-alive>
  </div>
</template>

<script>
import Audits from './audits/index'
import Pages from './pages'

export default {
  data() {
    return {
      menuItems: [
        { title: 'Pages', active: true, component: 'Pages' },
        { title: 'Audits', active: false, component: 'Audits' }
      ],
      currentComponent: 'Pages'
    }
  },
  components: {
    Audits,
    Pages
  },
  props: {
    websiteId: Number
  },
  methods: {
    toggleMenu(index) {
      this.menuItems.forEach((i) => {
        i.active = false
      })
      this.menuItems[index].active = true
      this.currentComponent = this.menuItems[index].component
    }
  }
}
</script>
