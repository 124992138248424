import Vue from 'vue'
import CrawlAuditWidget from '../vue/website/audits/crawl'

const initAuditWidget = (data) => {
  const props = {
    auditId: data.auditId
  }

  /* eslint-disable no-new */
  new Vue({
    el: document.getElementById('crawl-component'),
    render: (h) => h(CrawlAuditWidget, { props: props })
  })
  /* eslint-enable no-new */
}

Styxie.Initializers.WebsiteAudits = {
  show: (data) => {
    $(document).ready(() => {
      initAuditWidget(data)
    })
  }
}
