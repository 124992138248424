<template>
  <table class="table">
    <thead>
    <tr>
      <th></th>
      <th style="width: 10%">Created at</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="audit in audits">
        <td><a :href="audit.path">Open</a></td>
        <td>{{ audit.created_at }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    websiteId: Number
  },
  data() {
    return {
      audits: []
    }
  },
  created() {
    axios.get(`/api/websites/${this.websiteId}/audits`).then((res) => {
      this.audits = res.data.audits
    })
  }
}

</script>
