<template>
  <div>
    <div v-if="pendingAudit">
      Run audit to get metrics.
    </div>
    <div v-else>
      <div class='row' v-for="(val, propertyName) in metrics">
        <div class='col-lg'>
          <p class='my-0 py-0 bold wpt-property'>{{propertyName}}</p>
        </div>
        <div class='col-lg'>
          <span class='my-0 py-0 normal wpt-value' :class="metricClass(propertyName, val)">{{val}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import {
  BadgePlugin
} from 'bootstrap-vue'

Vue.use(BadgePlugin)

const PENDING_AUDIT_STATE = 'pending'
const WPT_METRICS = [
  'first_byte',
  'speed_index',
  'start_render', 
  'doc_complete_bytes_in', 
  'doc_complete_requests',
  'load_time',
  'fully_loaded_time',
  'fully_loaded_requests',
  'fully_loaded_bytes_in'
]

export default {
  props: ['value', 'params'],
  data() {
    return {
    }
  },
  methods: {
    metricClass(property, value) {
      if (this.value.hasOwnProperty(`${property}_score_dictionary`)) {
        if (this.value[`${property}_score_dictionary`].pass_ceiling < value) {
          return 'text-danger'
        }
      }
      return 'text-default'
    }
  },
  computed: {
    pendingAudit() {
      return this.value.state === PENDING_AUDIT_STATE
    },
    metrics() {
      if (this.pendingAudit) return 
      
      return WPT_METRICS.reduce((memo, key) => {
        memo[key] = this.value[key]
        return memo
      }, {})
    }
  }
}
</script>

<style scoped>
 .bold {
  font-weight: bold;
 }
 .normal {
  font-weight: 100;
 }

 .wpt-property, .wpt-value {
  font-size: 9px;
 }

</style>
