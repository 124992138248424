<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between mt-2">
      <p class='mb-2 pt-0'>In the speed audit we run all commercial pages through PageSpeed Insights and
        WebPageTest.org
        to get feedback on issues with the site's load time.</p>
    </div>
    <div class="my-2">
      <label for="wpt-location">WPT Location</label>
      <select name="wpt-location" class='form-control' id="wpt-location">
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </select>
    </div>

    <PagesTable :auditId="auditId" ref="pagesTable"/>
  </div>

</template>

<script>

import PagesTable from './speed/pages_table'

export default {
  name: 'PageAuditWidget',
  props: {
    noPages: Boolean,
    auditId: Number
  },
  data() {
    return {
      showPlaceholder: this.noPages
    }
  },
  methods: {},
  components: { PagesTable }
}

</script>
